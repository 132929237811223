import { getEnvironment } from './utils/getEnvironment';
declare global {
	interface Window {
		initializeRemote: (remoteKey: string) => Promise<void>;
	}
}

window.initializeRemote = async (remoteKey: string) => {
	const { getRemote, getFederatedEnv } = await import(
		'@innovyze/lib_get_remote'
	);

	const environment = await getEnvironment();
	// @ts-expect-error wrong type
	const federatedEnv = getFederatedEnv(environment);
	// @ts-expect-error unknown remoteKey type
	const remote = getRemote(remoteKey, federatedEnv);

	const [remoteReference] = Object.values(remote);
	const [key, url] = remoteReference.split('@');

	return new Promise(resolve => {
		const script = document.createElement('script');

		script.src = url;
		script.async = true;
		script.onload = () => {
			// @ts-expect-error unknown key type
			resolve(window[key]);
		};
		document.head.appendChild(script);
	});
};

import('./index');
